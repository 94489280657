import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './FormSubmissionPage.module.css'; // Reuse styles for consistency
import logo from '../assets/fanup_logo_white.png';
import CustomNavbar from './CustomNavbar';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function RegistrationDetailsPage() {
  const [registrationDetails, setRegistrationDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const collegeName = sessionStorage.getItem('collegeName');
  const tournamentName = useLocation().state.tournamentName;

  useEffect(() => {
    const fetchRegistrationDetails = async () => {
      try {
        const response = await fetch(`${REACT_APP_BACKEND_URL}/get-registration-details`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            collegeName,
            tournamentName,
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setRegistrationDetails(data.registration);
        } else {
          const result = await response.json();
          MySwal.fire({
            title: 'Error',
            text: result.error || 'Could not fetch registration details.',
            icon: 'error',
            color: '#fff',
            background: '#333',
          });
        }
      } catch (error) {
        console.error('Error fetching registration details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRegistrationDetails();
  }, [collegeName, tournamentName, REACT_APP_BACKEND_URL]);

  const handleModifyRegistration = () => {
    navigate('/interuniversitysignup', {
      state: {
        tournamentName,
        selectedSports: registrationDetails.sports,
        selectedPlayers: registrationDetails.players, // Pass the full updated structure
      },
    });
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!registrationDetails) {
    return <p>No registration details found.</p>;
  }

  return (
    <div className={styles.formSubmissionPage}>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.pageTitle}>{tournamentName} Registration Details</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.centerContainer}>
        <div className={styles.formContainer}>
          <h2 className={styles.headerText}>Your Registration Details</h2>
          <div className={styles.summarySection}>
            <h3 className={styles.summaryTitle}>Selected Sports and Players:</h3>
            <ul className={styles.summaryList}>
              {registrationDetails.sports.map((sport, index) => (
                <li key={index} className={styles.summaryItem}>
                  <div className={styles.sportTitleContainer}>
                    <span
                      className={styles.sportTitle}
                      style={{
                        background: `linear-gradient(to right, #FFD700, #FF8C00)`,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                      }}
                    >
                      {sport} Players
                    </span>
                  </div>
                  <ul className={styles.playerList}>
                    {registrationDetails.players[sport]?.map((player, idx) => (
                      <li key={player._id} className={styles.playerItem}>
                        {player.fullName}
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <button
            className={styles.submitButton}
            onClick={handleModifyRegistration}
          >
            Modify Registration
          </button>
        </div>
      </div>
    </div>
  );
}

export default RegistrationDetailsPage;
