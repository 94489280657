import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import logo from '../assets/fanup_logo_white.png';
import LoadingSpinner from './LoadingSpinner';
import styles from './UserSignupPage.module.css';
import CustomNavbar from './CustomNavbar';
import imageCompression from 'browser-image-compression';
import heic2any from 'heic2any';

const MySwal = withReactContent(Swal);

function UserSignupPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPlayer, setIsPlayer] = useState(true);
  const [teamLogoFile, setTeamLogoFile] = useState(null);
  const [error, setError] = useState('');
  const [tournaments, setTournaments] = useState([]);
  const [registeredTournaments, setRegisteredTournaments] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(location.state?.tournamentName || '');
  const [collegeName, setCollegeName] = useState(location.state?.collegeName || sessionStorage.getItem('collegeName') || 'undefined');
  const [coordinatorName, setCoordinatorName] = useState(location.state?.coordinatorName || sessionStorage.getItem('userFullName') || 'undefined');
  const [coordinatorEmail, setCoordinatorEmail] = useState(sessionStorage.getItem('userEmail') || '');
  const [coordinatorNumber, setCoordinatorNumber] = useState(location.state?.coordinatorNumber || sessionStorage.getItem('userPhoneNumber') || 'undefined');
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [isPlayerRegistrationEnabled, setIsPlayerRegistrationEnabled] = useState(true);
  const [isTeamRegistrationEnabled, setIsTeamRegistrationEnabled] = useState(true);
  const [filesArray, setFilesArray] = useState([]); // Array to store all files
  const [gender, setGender] = useState("")
  
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const token = localStorage.getItem('authToken');

    if (!token) {
      MySwal.fire({
        title: 'Authentication Required',
        text: 'You must log in with Google before signing up for a tournament.',
        icon: 'warning',
        confirmButtonText: 'OK',
        color:"#fff",
        background:"#333",
        theme:"dark",
        backdrop: true,
      }).then(() => {
        navigate('/');
      });
      return;
    }

    if (collegeName === "undefined" || coordinatorName === "undefined" || coordinatorNumber === "undefined") {
      const fetchUserDetails = async () => {
        try {
          const response = await fetch(`${BACKEND_URL}/fetch-college-name/college-name`, {
            credentials: 'include',
          });

          if (response.ok) {
            const data = await response.json();
            setCollegeName(data.collegeName);
            setCoordinatorName(data.fullName);
            setCoordinatorNumber(data.phoneNumber);
            sessionStorage.setItem('collegeName', data.collegeName);
            sessionStorage.setItem('userFullName', data.fullName);
            sessionStorage.setItem('userPhoneNumber', data.phoneNumber);
          } else {
            MySwal.fire({
              title: 'Information Not Found',
              text: 'You need to be logged in to register for tournaments...',
              icon: 'error',
              color:"#fff",
              background:"#333",
              confirmButtonText: 'OK',
              theme:"dark-theme",
              customClass:"popup",
              backdrop: true,
            }).then(() => {
              navigate('/');
            });
          }
        } catch (error) {
          console.error('Error fetching user details:', error);
          setError('An error occurred while fetching user details.');
        }
      };

      fetchUserDetails();
    }
  }, [collegeName, coordinatorName, coordinatorNumber, BACKEND_URL, navigate]);

useEffect(() => {
  const fetchTournaments = async () => {
    try {
      const [tournamentResponse, registeredResponse] = await Promise.all([
        fetch(`${BACKEND_URL}/tournament/all`, {
          credentials: 'include',
        }),
        fetch(`${BACKEND_URL}/registered-tournaments`, {
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }),
      ]);

      const tournamentsData = await tournamentResponse.json();
      const registeredTournamentsData = registeredResponse.ok ? await registeredResponse.json() : [];
      console.log(tournamentsData)
      if (tournamentResponse.ok) {
        setTournaments(
          tournamentsData.filter(
            (tournament) => Array.isArray(registeredTournamentsData) && !registeredTournamentsData.includes(tournament.tournamentName)
          )
        );
        setRegisteredTournaments(registeredTournamentsData);
      } else {
        setError('Failed to fetch tournaments.');
      }
    } catch (error) {
      console.error('Error fetching tournaments:', error);
      setError('An error occurred while fetching tournaments.');
    }
  };

  fetchTournaments();
}, [BACKEND_URL]);


useEffect(() => {
  const fetchTournamentDetails = async () => {
    if (selectedTournament) {
      setLoading(true); // Start loading spinner
      try {
        const response = await fetch(`${BACKEND_URL}/tournament/${selectedTournament}`, {
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setFormFields(isPlayer ? data.playerFormFields || [] : data.teamFormFields || []);
          setIsPlayerRegistrationEnabled(data.isPlayerRegistrationEnabled || false);
          setIsTeamRegistrationEnabled(data.isTeamRegistrationEnabled || false);
          setLoading(false); // Start loading spinner
        } else {
          console.error('Failed to fetch tournament details');
        }
      } catch (error) {
        console.error('Error fetching tournament details:', error);
      }
    }
  };

  fetchTournamentDetails();
}, [selectedTournament, isPlayer, BACKEND_URL]);

useEffect(() => {
  const checkRegistrationLimits = async () => {
    if (selectedTournament && gender) {
      try {
        const response = await fetch(`${BACKEND_URL}/tournament/${selectedTournament}/registrations`, {
          credentials: 'include',
        });
        
        if (response.ok) {
          const { numCisMen, numNonCisMen, currentCisMen, currentNonCisMen } = await response.json();

          // Ensure limits are numbers
          const cisLimit = Number(numCisMen);
          const nonCisLimit = Number(numNonCisMen);

          if ((gender === 'Cis-Man' && currentCisMen >= cisLimit) ||
              (gender === 'Non-Cis Man' && currentNonCisMen >= nonCisLimit)) {
            MySwal.fire({
              title: 'Registration Full',
              text: `Registration for ${gender} is closed. You will be added to the waitlist.`,
              icon: 'info',
              confirmButtonText: 'OK',
              color:"#fff",
              background:"#333",
            });
          }
        } else {
          console.error('Failed to fetch registration limits');
        }
      } catch (error) {
        console.error('Error checking registration limits:', error);
      }
    }
  };

  checkRegistrationLimits();
}, [selectedTournament, gender, BACKEND_URL]);

  const toggleRole = (role) => {
    setIsPlayer(role === 'player');
    setTeamLogoFile(null); // Clear team logo file if switching to player
  };

  const handleFileChange = async (e, fieldLabel = null, index = null) => {
    const file = e.target.files[0];
    
    if (!file) return; // Exit if no file is selected
  
    let fileToCompress = file;
    let isHEIC = false;
  
    // Check if the file is in HEIC format and convert if needed
    if (file.type === 'image/heic') {
      isHEIC = true;
      try {
        const convertedBlob = await heic2any({ blob: file, toType: 'image/jpeg' });
        fileToCompress = new File([convertedBlob], `${file.name.split('.')[0]}.jpeg`, { type: 'image/jpeg' });
      } catch (conversionError) {
        console.error('Error converting HEIC to JPEG:', conversionError);
        setError('Could not process HEIC image. Please try a different file format.');
        return;
      }
    }
  
    // Set compression options
    const options = {
      maxSizeMB: 10,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
      maxIteration: 3,
      initialQuality: 0.75,
    };
  
    try {
      const compressedFile = await imageCompression(fileToCompress, options);
      setFilesArray((prevFiles) => [
        ...prevFiles,
        { file: compressedFile, label: fieldLabel },
      ]);
  
      // Calculate the size difference after compression
      const originalSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      const compressedSizeMB = (compressedFile.size / (1024 * 1024)).toFixed(2);
  
      // Display compression details in a Swal alert
      MySwal.fire({
        title: 'Compression Successful',
        html: `
          <p><strong>Original Size:</strong> ${originalSizeMB} MB</p>
          <p><strong>Compressed Size:</strong> ${compressedSizeMB} MB</p>
          <p><strong>Compression Ratio:</strong> ${(compressedSizeMB / originalSizeMB * 100).toFixed(2)}%</p>
          ${isHEIC ? '<p>HEIC file was successfully converted to JPEG before compression.</p>' : ''}
        `,
        icon: 'success',
        color: "#fff",
        background: "#333",
        confirmButtonText: 'OK',
        theme: "dark",
        backdrop: true,
      });
  
      setError('');
    } catch (compressionError) {
      console.error('Error compressing the image:', compressionError);
      setError('An error occurred while compressing the image.');
      MySwal.fire({
        title: 'Compression Failed',
        text: 'An error occurred during image compression. Please try again or use a different image format.',
        icon: 'error',
        color: "#fff",
        background: "#333",
        confirmButtonText: 'OK',
        theme: "dark",
        backdrop: true,
      });
    }
  };
  
  
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const formData = new FormData();
    formData.append('role', isPlayer ? 'player' : 'owner');
    formData.append('tournamentName', selectedTournament);
    formData.append('collegeName', collegeName);
    formData.append('email', coordinatorEmail);
    formData.append('fullName', coordinatorName);
    formData.append('phoneNumber', coordinatorNumber);
    if(isPlayer)
    {
      formData.append('gender', gender);
    }
    // Append dynamic fields
    formFields.forEach((field) => {
      formData.append(field.label, e.target[field.label].value);
    });
  
    // Append each file in filesArray to FormData with its label
    filesArray.forEach(({ file, label }) => {
      formData.append(label, file); // Use label as the field name
      console.log(`Appending file: ${file.name}, label: ${label}`);
    });
  
    const endpoint = isPlayer ? 'player-signup' : 'team-signup';
    try {
      const response = await fetch(`${BACKEND_URL}/${endpoint}`, {
        method: 'POST',
        credentials: 'include',
        body: formData,
      });
  
      const result = await response.json();
      if (response.ok) {
        MySwal.fire({
          title: "Success",
          text: "Thank you for registering! Confirmation will be sent shortly.",
          icon: "success",
          color:"#fff",
          background:"#333",
          theme:"dark",
          backdrop: true,
        });
        setLoading(false)
        navigate(`/tournament/${selectedTournament}`);
      } else {
        setLoading(false)
        setError(result.error);
      }
    } catch (error) {
      setError(error);
      MySwal.fire({
        title: "Error",
        text: error,
        icon: "success",
        color:"#fff",
        background:"#333",
        theme:"dark",
        backdrop: true,
      });
    }
    setLoading(false)
  };
  
  
  

  return (
    <div className={styles.signupPage}>
      {loading && <LoadingSpinner />} {/* Render the spinner if loading */}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>Sign Up Page</span>
        </div>
      </nav>
      <CustomNavbar /> {/* Use the navbar component here */}
      <div className={styles.centerContainer}>
        <div className={styles.formContainer}>
          <h2 className={styles.signupText}>Sign Up</h2>
          <div className={styles.roleToggle}>
            <button
              className={`${styles.toggleButton} ${isPlayer ? styles.active : ''}`}
              onClick={() => toggleRole('player')}
            >
              Player
            </button>
            <button
              className={`${styles.toggleButton} ${!isPlayer ? styles.active : ''}`}
              onClick={() => toggleRole('owner')}
            >
              Owner
            </button>
          </div>
          <form className={styles.form} onSubmit={handleSubmit}>
  <div className={styles.formGroup}>
    <label htmlFor="tournamentName">Select Tournament:</label>
    <select
      id="tournamentName"
      className={styles.inputField}
      value={selectedTournament}
      onChange={(e) => setSelectedTournament(e.target.value)}
      required
    >
      <option value="">Select Tournament</option>
      {tournaments.map((tournament) => {

        if(tournament.tournamentName)
        { 
          return(
          <option key={tournament._id} value={tournament.tournamentName}>
          {tournament.tournamentName}
        </option>)
        }
        else
        {
          return;
        }
        
})}
    </select>
  </div>

  {isPlayer ? (
  isPlayerRegistrationEnabled ? (
    <div className={styles.formGroup}>
      <div className={styles.formGroup}>
        <label htmlFor="gender">Gender</label>
        <select
          id="gender"
          name="gender"
          className={styles.inputField}
          value={gender}
          onChange={(e) => setGender(e.target.value)}
          required
        >
          <option value="">Select Gender</option>
          <option value="Cis-Man">Cis-Man</option>
          <option value="Non-Cis Man">Non-Cis Man</option>
        </select>
      </div>
      <div className={styles.formGroup}>
    <label htmlFor="coordinatorName">Player Name:</label>
    <input
      type="text"
      id="coordinatorName"
      className={styles.inputField}
      value={coordinatorName}
      onChange={(e) => setCoordinatorName(e.target.value)}
      required
    />
  </div>
  <div className={styles.formGroup}>
    <label htmlFor="coordinatorEmail">Player Email ID:</label>
    <input
      type="email"
      id="coordinatorEmail"
      className={styles.inputField}
      value={coordinatorEmail}
      onChange={(e) => setCoordinatorEmail(e.target.value)}
      required
    />
  </div>
  <div className={styles.formGroup}>
    <label htmlFor="coordinatorNumber">Phone Number:</label>
    <input
      type="tel"
      id="coordinatorNumber"
      className={styles.inputField}
      value={coordinatorNumber}
      onChange={(e) => setCoordinatorNumber(e.target.value)}
      required
    />
  </div>
  {formFields.map((field, index) => (
  <div key={index} className={styles.formGroup}>
    {field.type === 'file' ? (
      <div className={styles.uploadField}>
        <label htmlFor={field.label} className={styles.fileLabel}>{field.label}</label>
        <div className={styles.fileInputWrapper}>
          <input
            type="file"
            id={field.label}
            accept="image/*"
            onChange={(e) => handleFileChange(e, field.label, index)}
            className={styles.inputField}
            required
          />
        </div>
      </div>
    ) : (
      <div className={styles.formGroup}>
        <label>{field.label}</label>
        <input
          type={field.type}
          name={field.label}
          className={styles.inputField}
          required
        />
      </div>
    )}
  </div>
))}


    </div>
  ) : (
    <p className={styles.disabledText}>Player registration is currently disabled.</p>
  )
) : (
  isTeamRegistrationEnabled ? (
    <div className={styles.formGroup}>
      {formFields.map((field, index) => (
  <div key={index} className={styles.formGroup}>
    {field.type === 'file' ? (
      <div className={styles.uploadField}>
        <label htmlFor={field.label} className={styles.fileLabel}>{field.label}</label>
        <div className={styles.fileInputWrapper}>
          <input
            type="file"
            id={field.label}
            accept="image/*"
            onChange={(e) => handleFileChange(e, field.label, index)}
            className={styles.inputField}
            required
          />
          {filesArray.find(f => f.label === field.label)?.file?.name && (
            <span className={styles.fileName}>
              {filesArray.find(f => f.label === field.label)?.file?.name}
            </span>
          )}
        </div>
      </div>
    ) : (
      <div className={styles.formGroup}>
        <label>{field.label}</label>
        <input
          type={field.type}
          name={field.label}
          className={styles.inputField}
          required
        />
      </div>
    )}
  </div>
))}


    </div>
  ) : (
    <p className={styles.disabledText}>Team registration is currently disabled.</p>
  )
)}
  {error && <p className={styles.errorText}>{error}</p>}
  <button type="submit" className={styles.submitButton} disabled={isSubmitting}>
    Submit
  </button>
</form>

        </div>
      </div>
    </div>
  );
}

export default UserSignupPage;
