import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/fanup_logo_white.png';
import styles from './SportDetailPage.module.css';
import CustomNavbar from './CustomNavbar';
import LoadingSpinner from './LoadingSpinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

function SportDetailPage() {
  const [isLoading, setIsLoading] = useState(false);
  const { sportName, collegeName } = useParams();
  const [uploadStatus, setUploadStatus] = useState(false);
  const location = useLocation();
  const {
    sport = { name: '', playerListUploaded: false },
  } = location.state || {};
  const [coachInfo, setCoachInfo] = useState({
    coachName: '',
    coachEmail: '',
    coachNumber: '',
  });
  const [playersFile, setPlayersFile] = useState(null);
  const navigate = useNavigate();

  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // Fetch upload status
  const fetchUploadStatus = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${BACKEND_URL}/register-sport/status/${sportName}/${collegeName}`,
        {
          credentials: 'include',
        }
      );
      const result = await response.json();
      setIsLoading(false);
      setUploadStatus(result.uploaded);
    } catch (error) {
      console.error('Error fetching upload status:', error);
    }
  };

  // Download players
  const downloadPlayers = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `${BACKEND_URL}/register-sport/download/${sportName}/${collegeName}`,
        {
          method: 'GET',
          credentials: 'include',
        }
      );

      if (!response.ok) {
        const error = await response.json();
        alert(`Error: ${error.message}`);
        return;
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${collegeName}_${sportName}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
      MySwal.fire({
        title: 'Success',
        text: 'Players downloaded successfully',
        icon: 'success',
        confirmButtonText: 'OK',
        color:"#fff",
        background:"#333",
        theme:"dark",
        backdrop: true,});
    } catch (error) {
      console.error('Error downloading players:', error);
      alert('An error occurred while downloading the file.');
    }
  };

  useEffect(() => {
    fetchUploadStatus();
  }, []);

  const handleCoachChange = (e) => {
    const { name, value } = e.target;

    setCoachInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setPlayersFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append('collegeName', collegeName);
    formData.append('coachName', coachInfo.coachName);
    formData.append('coachEmail', coachInfo.coachEmail);
    formData.append('coachNumber', coachInfo.coachNumber);
    formData.append('playersFile', playersFile);

    try {
      const response = await fetch(
        `${BACKEND_URL}/register-sport/${sport.name}`,
        {
          method: 'POST',
          credentials: 'include',
          body: formData,
        }
      );
      const result = await response.json();
      if (response.ok) {
        console.log('Registration successful:', result);
        setIsLoading(false);
        MySwal.fire({
          title: 'Success',
          text: 'Registration successful',
          icon: 'success',
          confirmButtonText: 'OK',
          color:"#fff",
          background:"#333",
          theme:"dark",
          backdrop: true,
        });
        fetchUploadStatus(); // Refresh upload status after successful upload
        navigate(`/collegedashboard`);
      } else {
        console.error('Registration failed:', result);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className={styles.dashboardContainer}>
      {isLoading && <LoadingSpinner />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>{sport.name.toUpperCase()} DETAILS</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.page}>
        <div className={styles.ribbon}>
          <div className={styles.ribbonText}>COACH REGISTRATION</div>
        </div>
        <div className={styles.formContainerCoach}>
          <form className={styles.coachRegistrationGrid} onSubmit={handleSubmit}>
            <h1 className={styles.sportName}>{sport.name}</h1>
            <input
              type="text"
              name="coachName"
              placeholder="Coach Name*"
              className={styles.input}
              value={coachInfo.coachName}
              onChange={handleCoachChange}
              required
            />
            <input
              type="email"
              name="coachEmail"
              placeholder="Coach Email*"
              className={styles.input}
              value={coachInfo.coachEmail}
              onChange={handleCoachChange}
              required
            />
            <input
              type="text"
              name="coachNumber"
              placeholder="Coach Phone Number*"
              className={styles.input}
              value={coachInfo.coachNumber}
              onChange={handleCoachChange}
              required
            />
            <input
              type="file"
              name="playersFile"
              className={styles.input}
              onChange={handleFileChange}
              required
            />
            {uploadStatus ? (
              <>
                <p style={{ color: 'green' }}>Players have already been uploaded.</p>
                <button type="button" onClick={downloadPlayers} className={styles.downloadButton}>
                  Download Uploaded Players
                </button>
              </>
            ) : (
              <p style={{ color: 'red' }}>No players have been uploaded yet.</p>
            )}
            <button type="submit" className={styles.submitButton}>
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SportDetailPage;
