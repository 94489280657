import React, { useState, useEffect } from 'react';
import styles from './ChooseSportsPage.module.css';
import logo from '../assets/fanup_logo_white.png';
import CustomNavbar from "./CustomNavbar";
import LoadingSpinner from './LoadingSpinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const sports = [
  { name: 'Basketball', gradient: 'linear-gradient(to right, #F0B672, #E58A1F)' },
  { name: 'Football', gradient: 'linear-gradient(to right, #98E085, #4DA736)' },
  { name: 'Tennis', gradient: 'linear-gradient(to right, #689DCF, #1F63A2)' },
  { name: 'Badminton', gradient: 'linear-gradient(to right, #FFD700, #FF8C00)' },
  { name: 'Cricket', gradient: 'linear-gradient(to right, #87CEEB, #4682B4)' },
  { name: 'Pool', gradient: 'linear-gradient(to right, #00BFFF, #1E90FF)' },
  { name: 'Squash', gradient: 'linear-gradient(to right, #FF6347, #FF4500)' },
  { name: 'Table-Tennis', gradient: 'linear-gradient(to right, #3CB371, #2E8B57)' },
  { name: 'Chess', gradient: 'linear-gradient(to right, #FFDAB9, #E6B800)' },
  { name: 'Volleyball', gradient: 'linear-gradient(to right, #F08080, #CD5C5C)' },
  { name: 'Powerlifting', gradient: 'linear-gradient(to right, #A52A2A, #800000)' },
  { name: 'Swimming', gradient: 'linear-gradient(to right, #B0C4DE, #5F9EA0)' }
];

function ChooseSportsPage({ selectedSports, setSelectedSports, onNext, tournamentName }) {
  const [isLoading, setIsLoading] = useState(false);

  const toggleSportSelection = (sportName) => {
    if (selectedSports.includes(sportName)) {
      setSelectedSports(selectedSports.filter((s) => s !== sportName));
    } else {
      setSelectedSports([...selectedSports, sportName]);
    }
  };

  return (
    <div className={styles.sportsSelectionPage}>
      {isLoading && <LoadingSpinner />}
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.pageTitle}>{tournamentName} Registration</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.centerContainer}>
        <div className={styles.selectionContainer}>
        <h2 className={styles.headerText}> Register for the following sports:</h2>
          <div className={styles.sportsList}>
            {sports.map((sport) => (
              <button
                key={sport.name}
                className={`${styles.sportButton} ${
                  selectedSports.includes(sport.name) ? styles.activeSport : ''
                }`}
                style={{
                  background: selectedSports.includes(sport.name) ? sport.gradient : '#333',
                }}
                onClick={() => toggleSportSelection(sport.name)}
              >
                {sport.name}
                {selectedSports.includes(sport.name) && (
      <div className={styles.sportStatus}>Selected</div>
    )}
              </button>
            ))}
          </div>
          <button
            className={styles.submitButton}
            onClick={onNext}
            disabled={selectedSports.length === 0}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChooseSportsPage;
