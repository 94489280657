import React, { useState, useEffect } from 'react';
import styles from './AdminVerification.module.css';
import logo from '../assets/fanup_logo_white.png';
import CustomNavbar from './CustomNavbar';

function AdminVerification() {
  const [college, setCollege] = useState('');
  const [adminCode, setAdminCode] = useState('');
  const [userCode, setUserCode] = useState('');
  const [tournamentName, setTournamentName] = useState('');
  const [message, setMessage] = useState('');
  const [verificationCodes, setVerificationCodes] = useState([]);
  const [editingCode, setEditingCode] = useState(null);
  const [tournaments, setTournaments] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedTournament, setSelectedTournament] = useState(null);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [teamName, setTeamName] = useState(''); 
  const [teams, setTeams] = useState([]);
  const [tournamentType, setTournamentType] = useState('Inter-University');
  const [numCisMen, setNumCisMen] = useState('');
  const [numNonCisMen, setNumNonCisMen] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [location, setLocation] = useState('');
  const [registrationFees, setRegistrationFees] = useState('');
  const [colleges, setColleges] = useState([]);
const [collegeName, setCollegeName] = useState('');
const [editingCollegeId, setEditingCollegeId] = useState(null);
  
  useEffect(() => {
    fetchVerificationCodes();
    fetchTournaments();
    fetchColleges(); 
  }, []);


  const fetchColleges = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/colleges`, {
        credentials: 'include',
      });
      const data = await response.json();
      setColleges(data);
    } catch (error) {
      console.error('Error fetching colleges:', error);
    }
  };
  
  // Add or update a college
  const saveCollege = async (e) => {
    e.preventDefault();
    const method = editingCollegeId ? 'PUT' : 'POST';
    const url = editingCollegeId
      ? `${process.env.REACT_APP_BACKEND_URL}/admin/colleges/${editingCollegeId}`
      : `${process.env.REACT_APP_BACKEND_URL}/admin/colleges`;
  
    try {
      const response = await fetch(url, {
        method,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ collegeName }),
        credentials: 'include',
      });
  
      if (response.ok) {
        fetchColleges();
        setCollegeName('');
        setEditingCollegeId(null);
      } else {
        console.error('Failed to save college');
      }
    } catch (error) {
      console.error('Error saving college:', error);
    }
  };
  
  // Delete a college
  const deleteCollege = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/colleges/${id}`, {
        method: 'DELETE',
        credentials: 'include',
      });
  
      if (response.ok) {
        fetchColleges();
      } else {
        console.error('Failed to delete college');
      }
    } catch (error) {
      console.error('Error deleting college:', error);
    }
  };


  const addTeam = async (teamData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${selectedTournament}/team`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', 
        body: JSON.stringify(teamData),
      });
      if (response.ok) {
        fetchTeams(selectedTournament); // Refresh team list
        setTeamName('');
      } else {
        console.error('Failed to add team');
      }
    } catch (error) {
      console.error('Error adding team:', error);
    }
  };

  const updateTeam = async (teamId, updatedData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${selectedTournament}/team/${teamId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
        credentials: 'include', 
      });
      if (response.ok) {
        fetchTeams(selectedTournament); // Refresh team list
      } else {
        console.error('Failed to update team');
      }
    } catch (error) {
      console.error('Error updating team:', error);
    }
  };

  const deleteTeam = async (teamId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${selectedTournament}/team/${teamId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        fetchTeams(selectedTournament); // Refresh team list
      } else {
        console.error('Failed to delete team');
      }
    } catch (error) {
      console.error('Error deleting team:', error);
    }
  };

  const fetchTeams = async (tournamentName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${tournamentName}/teams`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setTeams(data);
        setSelectedTournament(tournamentName);
      } else {
        console.error('Failed to fetch teams');
      }
    } catch (error) {
      console.error('Error fetching teams:', error);
    }
  };

  const deletePlayer = async (playerId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${selectedTournament}/player/${playerId}`, {
        method: 'DELETE',
        credentials: 'include',
      });
      if (response.ok) {
        fetchPlayers(selectedTournament); // Refresh player list
      } else {
        console.error('Failed to delete player');
      }
    } catch (error) {
      console.error('Error deleting player:', error);
    }
  };

  const fetchTournaments = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournaments`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setTournaments(data);
      } else {
        console.error('Failed to fetch tournaments');
      }
    } catch (error) {
      console.error('Error fetching tournaments:', error);
    }
  };

  const fetchPlayers = async (tournamentName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournament/${tournamentName}/players`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setPlayers(data);
        setSelectedTournament(tournamentName);
      } else {
        console.error('Failed to fetch players');
      }
    } catch (error) {
      console.error('Error fetching players:', error);
    }
  };

  const fetchVerificationCodes = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/verification-codes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      const data = await response.json();
      if (response.ok) {
        setVerificationCodes(data);
      } else {
        console.error('Failed to fetch verification codes');
      }
    } catch (error) {
      console.error('Error fetching verification codes:', error);
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/tournaments/${editingCode}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ college, adminCode, userCode, tournamentName, tournamentType }),
        credentials: 'include',
      });
      if (response.ok) {
        setMessage('Verification code updated successfully');
        fetchVerificationCodes();
        setEditingCode(null);
        setCollege('');
        setAdminCode('');
        setUserCode('');
        setTournamentName('');
        setTournamentType('Inter-University');
      } else {
        console.error('Failed to update verification code');
      }
    } catch (error) {
      console.error('Error updating verification code:', error);
    }
  };
  

const handleEdit = (item) => {
  if (item.adminCode) {
    // Editing a verification code
    setEditingCode(item._id);
    setCollege(item.college);
    setAdminCode(item.adminCode);
    setUserCode(item.userCode);
    setTournamentName(item.tournamentName);
    setTournamentType(item.tournamentType || 'Inter-University'); // Default if not set
    setSelectedTournament(null); // Ensure no tournament is selected
  } else {
    // Editing a player
    setEditingCode(item._id);
    setFullName(item.fullName);
    setEmail(item.email);
    setSelectedTournament(selectedTournament);
  }
};



const handleSubmit = async (e) => {
  e.preventDefault();
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/verification-codes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ college, adminCode, userCode, tournamentName, tournamentType }),
      credentials: 'include',
    });
    if (response.ok) {
      setMessage('Verification code entry added successfully');
      fetchVerificationCodes();
    } else {
      setMessage('Failed to add verification code entry');
    }
  } catch (error) {
    console.error('Error adding verification code entry:', error);
    setMessage('An error occurred while adding the entry');
  }
};

const deleteVerificationCode = async (codeId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/verification-codes/${codeId}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (response.ok) {
      setMessage('Verification code deleted successfully');
      fetchVerificationCodes(); // Refresh the list of codes
    } else {
      console.error('Failed to delete verification code');
    }
  } catch (error) {
    console.error('Error deleting verification code:', error);
  }
};

const deleteTournament = async (tournamentId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tournament-management/${tournamentId}`, {
      method: 'DELETE',
      credentials: 'include',
    });
    if (response.ok) {
      setMessage('Tournament deleted successfully');
      fetchTournaments(); // Refresh the list of tournaments
    } else {
      console.error('Failed to delete tournament');
    }
  } catch (error) {
    console.error('Error deleting tournament:', error);
  }
};


const editTournament = async (tournamentId, updatedData) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/tournament-management/${tournamentId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedData),
      credentials: 'include',
    });
    if (response.ok) {
      setMessage('Tournament updated successfully');
      fetchTournaments(); // Refresh tournament list
    } else {
      console.error('Failed to update tournament');
    }
  } catch (error) {
    console.error('Error updating tournament:', error);
  }
};


const handleTournamentEdit = (tournament) => {
  setTournamentName(tournament.tournamentName || '');
  setNumCisMen(tournament.numCisMen || '');
  setNumNonCisMen(tournament.numNonCisMen || '');
  setStartDate(tournament.startDate || '');
  setEndDate(tournament.endDate || '');
  setLocation(tournament.location || '');
  setRegistrationFees(tournament.registrationFees || '');
  setEditingCode(tournament._id); // Track which tournament is being edited
};



  return (
    <div className={styles.adminPage}>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.registrationText}>Admin Verification</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.centerContainer}>
        <div className={styles.formContainer}>
          <h2>Verification Code Form</h2>
          <form className={styles.form} onSubmit={editingCode ? handleUpdate : handleSubmit}>
          <input
            type="text"
            placeholder="College"
            value={college}
            onChange={(e) => setCollege(e.target.value)}
            required
            className={styles.inputField}
          />
          <input
            type="text"
            placeholder="Admin Code"
            value={adminCode}
            onChange={(e) => setAdminCode(e.target.value)}
            required
            className={styles.inputField}
          />
          <input
            type="text"
            placeholder="User Code"
            value={userCode}
            onChange={(e) => setUserCode(e.target.value)}
            required
            className={styles.inputField}
          />
          <select
            value={tournamentType}
            onChange={(e) => setTournamentType(e.target.value)}
            required
            className={styles.inputField}
          >
            <option value="Inter-University">Inter-University</option>
            <option value="Intra-University">Intra-University</option>
          </select>
          {tournamentType === 'Intra-University' && (
            <input
              type="text"
              placeholder="Tournament Name"
              value={tournamentName}
              onChange={(e) => setTournamentName(e.target.value)}
              required={tournamentType === 'Intra-University'}
              className={styles.inputField}
            />
          )}
          <button type="submit" className={styles.submitButton}>
            {editingCode ? 'Update Verification Code' : 'Add Verification Code'}
          </button>
        </form>

        </div>
        <div className={styles.verificationList}>
  <h2>Verification Codes</h2>
  <ul>
    {verificationCodes.map((code) => (
      <li key={code._id} className={styles.verificationItem}>
        <span>
          {code.college} - {code.tournamentName} ({code.tournamentType})
        </span>
        <div>
          <button onClick={() => handleEdit(code)} className={styles.editButton}>
            Edit
          </button>
          <button onClick={() => deleteVerificationCode(code._id)} className={styles.deleteButton}>
            Delete
          </button>
        </div>
      </li>
    ))}
  </ul>
  <h2>Manage Colleges</h2>
<ul className={styles.collegeList}>
  {colleges.map((college) => (
    <li key={college._id}>
      {college.collegeName}
      <button onClick={() => setEditingCollegeId(college._id)}>Edit</button>
      <button onClick={() => deleteCollege(college._id)}>Delete</button>
    </li>
  ))}
</ul>

<form onSubmit={saveCollege} className={styles.form}>
  <input
    type="text"
    placeholder="College Name"
    value={collegeName}
    onChange={(e) => setCollegeName(e.target.value)}
    required
    className={styles.inputField}
  />
  <button type="submit">
    {editingCollegeId ? 'Update College' : 'Add College'}
  </button>
</form>

</div>;
        <h2 className={styles.sectionTitle}>Select a Tournament</h2>
        <ul className={styles.tournamentList}>
  {tournaments.map((tournament) => (
    <li key={tournament._id} className={styles.tournamentItem}>
      <span className={styles.tournamentName}>{tournament.tournamentName}</span>
      <button
        onClick={() => {
          fetchPlayers(tournament.tournamentName);
          fetchTeams(tournament.tournamentName);
        }}
        className={styles.viewPlayersButton}
      >
        View Players & Teams
      </button>
      <button onClick={() => handleTournamentEdit(tournament)} className={styles.editButton}>
        Edit
      </button>
      <button onClick={() => deleteTournament(tournament._id)} className={styles.deleteButton}>
        Delete
      </button>
    </li>
  ))}
</ul>;

<form
  className={styles.form}
  onSubmit={(e) => {
    e.preventDefault();
    if (editingCode) {
      editTournament(editingCode, {
        tournamentName,
        numCisMen,
        numNonCisMen,
        startDate,
        endDate,
        location,
        registrationFees,
      });
    }
    setEditingCode(null); // Reset after editing
  }}
>
  <h2>{editingCode ? 'Edit Tournament' : 'Add Tournament'}</h2>
  <input
    type="text"
    placeholder="Tournament Name"
    value={tournamentName}
    onChange={(e) => setTournamentName(e.target.value)}
    required
    className={styles.inputField}
  />
  <input
    type="number"
    placeholder="Number of Cis-Men"
    value={numCisMen}
    onChange={(e) => setNumCisMen(e.target.value)}
    className={styles.inputField}
  />
  <input
    type="number"
    placeholder="Number of Non-Cis Men"
    value={numNonCisMen}
    onChange={(e) => setNumNonCisMen(e.target.value)}
    className={styles.inputField}
  />
  <input
    type="date"
    placeholder="Start Date"
    value={startDate}
    onChange={(e) => setStartDate(e.target.value)}
    className={styles.inputField}
  />
  <input
    type="date"
    placeholder="End Date"
    value={endDate}
    onChange={(e) => setEndDate(e.target.value)}
    className={styles.inputField}
  />
  <input
    type="text"
    placeholder="Location"
    value={location}
    onChange={(e) => setLocation(e.target.value)}
    className={styles.inputField}
  />
  <input
    type="number"
    placeholder="Registration Fees"
    value={registrationFees}
    onChange={(e) => setRegistrationFees(e.target.value)}
    className={styles.inputField}
  />
  <button type="submit" className={styles.submitButton}>
    {editingCode ? 'Update Tournament' : 'Add Tournament'}
  </button>
</form>

{selectedTournament && (
          <div>
            <h2>Players in {selectedTournament}</h2>
            <ul className={styles.playerList}>
            {players.map((player) => (
              <li key={player._id}>
                {player.fullName} - {player.email}
                <button className={styles.editButton} onClick={() => handleEdit(player)}>Edit</button>
                <button className={styles.deleteButton} onClick={() => deletePlayer(player._id)}>Delete</button>
              </li>
            ))}
          </ul>
            
            <h2>Teams in {selectedTournament}</h2>
            <ul className={styles.teamList}>
              {teams.map((team) => (
                <li key={team._id}>
                  <span>{team["Team Name"]}</span>
                  <button onClick={() => handleEdit(team, 'team')} className={styles.editButton}>Edit</button>
                  <button onClick={() => deleteTeam(team._id)} className={styles.deleteButton}>Delete</button>
                </li>
              ))}
            </ul>
            <form className={styles.form} onSubmit={(e) => {
              e.preventDefault();
              editingCode ? updateTeam(editingCode, { teamName }) : addTeam({ teamName });
            }}>
              <input
                type="text"
                placeholder="Team Name"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                required
                className={styles.inputField}
              />
              <button type="submit" className={styles.submitButton}>
                {editingCode ? 'Update Team' : 'Add Team'}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminVerification;
