import React, { useState, useEffect } from 'react';
import styles from './PlayerSelectionPage.module.css';
import logo from '../assets/fanup_logo_white.png';
import CustomNavbar from './CustomNavbar';

function PlayerSelectionPage({ selectedSports, selectedPlayers, setSelectedPlayers, onNext, onPrevious, tournamentName }) {

  const sports = [
    { name: 'Basketball', gradient: 'linear-gradient(to right, #F0B672, #E58A1F)' },
    { name: 'Football', gradient: 'linear-gradient(to right, #98E085, #4DA736)' },
    { name: 'Tennis', gradient: 'linear-gradient(to right, #689DCF, #1F63A2)' },
    { name: 'Badminton', gradient: 'linear-gradient(to right, #FFD700, #FF8C00)' },
    { name: 'Cricket', gradient: 'linear-gradient(to right, #87CEEB, #4682B4)' },
    { name: 'Pool', gradient: 'linear-gradient(to right, #00BFFF, #1E90FF)' },
    { name: 'Squash', gradient: 'linear-gradient(to right, #FF6347, #FF4500)' },
    { name: 'Table-Tennis', gradient: 'linear-gradient(to right, #3CB371, #2E8B57)' },
    { name: 'Chess', gradient: 'linear-gradient(to right, #FFDAB9, #E6B800)' },
    { name: 'Volleyball', gradient: 'linear-gradient(to right, #F08080, #CD5C5C)' },
    { name: 'Powerlifting', gradient: 'linear-gradient(to right, #A52A2A, #800000)' },
    { name: 'Swimming', gradient: 'linear-gradient(to right, #B0C4DE, #5F9EA0)' }
  ];

  const [availablePlayers, setAvailablePlayers] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const collegeName = sessionStorage.getItem('collegeName');

  useEffect(() => {
    const fetchPlayers = async () => {
      setIsLoading(true);
      const playersData = {};
      try {
        for (const sport of selectedSports) {
          const response = await fetch(
            `${BACKEND_URL}/get-players/players?sport=${sport}&collegeName=${collegeName}`,
            { method: 'GET', credentials: 'include' }
          );
          const result = await response.json();
          playersData[sport] = result.players || [];
        }
        setAvailablePlayers(playersData);
      } catch (error) {
        console.error('Error fetching players:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (selectedSports.length > 0) fetchPlayers();
  }, [selectedSports, BACKEND_URL, collegeName]);

  const togglePlayerSelection = (sport, player) => {
    const updatedPlayers = { ...selectedPlayers };
    if (!updatedPlayers[sport]) updatedPlayers[sport] = [];
  
    const existingPlayer = updatedPlayers[sport].find((p) => p._id === player._id);
    if (existingPlayer) {
      // Remove player if already selected
      updatedPlayers[sport] = updatedPlayers[sport].filter((p) => p._id !== player._id);
    } else {
      // Add player
      updatedPlayers[sport].push({ _id: player._id, fullName: player.fullName });
    }
  
    setSelectedPlayers(updatedPlayers);
  };
  
  const isPlayerSelected = (sport, player) =>
    selectedPlayers[sport]?.some((p) => p._id === player._id);
  

  const handlePlayerSelection = (player) => {
    setSelectedPlayers((prevSelectedPlayers) => [
      ...prevSelectedPlayers,
      { _id: player._id, fullName: player.fullName },
    ]);
  };
  
  // To remove a player (optional):
  const handlePlayerDeselection = (playerId) => {
    setSelectedPlayers((prevSelectedPlayers) =>
      prevSelectedPlayers.filter((player) => player._id !== playerId)
    );
  };
  

  return (
    <div className={styles.playerSelectionPage}>
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.pageTitle}>{tournamentName} Registration</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.centerContainer}>
        <div className={styles.selectionContainer}>
          <h2 className={styles.headerText}>Select Players for Each Sport:</h2>
          {isLoading ? (
            <p>Loading players...</p>
          ) : (
            selectedSports.map((sport) => (
              <div key={sport} className={styles.sportSection}>
  <h3
    className={styles.sportTitle}
    style={{
      background: sports.find((s) => s.name === sport)?.gradient || '#fff',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      textFillColor: 'transparent',
    }}
  >
    {sport}
  </h3>
  <div className={styles.playersGrid}>
  {availablePlayers[sport]?.map((player) => (
  <button
    key={player._id}
    className={`${styles.playerButton} ${
      isPlayerSelected(sport, player) ? styles.activePlayer : ''
    }`}
    onClick={() => togglePlayerSelection(sport, player)}
  >
    {player.fullName}
  </button>
))}

  </div>
</div>


            ))
          )}
          <div className={styles.navButtons}>
            <button className={styles.previousButton} onClick={onPrevious}>
              Back
            </button>
            <button
              className={styles.nextButton}
              onClick={onNext}
              disabled={Object.values(selectedPlayers).every((players) => players.length === 0)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlayerSelectionPage;
