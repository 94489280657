import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../assets/fanup_logo_white.png';
import styles from './SportsSelectionPage.module.css';
import CustomNavbar from "./CustomNavbar";
import LoadingSpinner from './LoadingSpinner';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);
const sports = [
  { name: 'Basketball', gradient: 'linear-gradient(to right, #F0B672, #E58A1F)' },
  { name: 'Football', gradient: 'linear-gradient(to right, #98E085, #4DA736)' },
  { name: 'Tennis', gradient: 'linear-gradient(to right, #689DCF, #1F63A2)' },
  { name: 'Badminton', gradient: 'linear-gradient(to right, #FFD700, #FF8C00)' },
  { name: 'Cricket', gradient: 'linear-gradient(to right, #87CEEB, #4682B4)' },
  { name: 'Pool', gradient: 'linear-gradient(to right, #00BFFF, #1E90FF)' },
  { name: 'Squash', gradient: 'linear-gradient(to right, #FF6347, #FF4500)' },
  { name: 'Table-Tennis', gradient: 'linear-gradient(to right, #3CB371, #2E8B57)' },
  { name: 'Chess', gradient: 'linear-gradient(to right, #FFDAB9, #E6B800)' },
  { name: 'Volleyball', gradient: 'linear-gradient(to right, #F08080, #CD5C5C)' },
  { name: 'Powerlifting', gradient: 'linear-gradient(to right, #A52A2A, #800000)' },
  { name: 'Swimming', gradient: 'linear-gradient(to right, #B0C4DE, #5F9EA0)' }
];

function SportsSelectionPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const isModifyMode = location.state?.modify || false;

  const [selectedSports, setSelectedSports] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [userCollegeName, setUserCollegeName] = useState(sessionStorage.getItem('collegeName') || '');

  useEffect(() => {
    const checkExistingSportsSelection = async () => {
      setIsLoading(true);

      try {
        const response = await fetch(`${BACKEND_URL}/get-sports?collegeName=${userCollegeName}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
        });

        const result = await response.json();
        if (response.ok && result.selectedSports?.length > 0 && !isModifyMode) {
          // If sports are already selected, navigate directly to dashboard
          navigate(`/collegedashboard`);
        }
      } catch (error) {
        console.error('Error checking sports selection:', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (userCollegeName) {
      checkExistingSportsSelection();
    }
  }, [userCollegeName, BACKEND_URL, navigate]);

  useEffect(() => {
    if (isModifyMode) {
      fetchSelectedSports(userCollegeName);
    }
  }, [isModifyMode, userCollegeName]);

  const fetchSelectedSports = async (collegeName) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${BACKEND_URL}/get-sports?collegeName=${collegeName}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
      });

      const result = await response.json();
      if (response.ok && result.selectedSports?.length > 0) {
        setSelectedSports(result.selectedSports.map((sport) => sport.name));
      }
    } catch (error) {
      console.error('Error fetching selected sports:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const toggleSportSelection = (sport) => {
    const sportName = sport.name;
    if (selectedSports.includes(sportName)) {
      setSelectedSports(selectedSports.filter((s) => s !== sportName));
    } else {
      setSelectedSports([...selectedSports, sportName]);
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const sportsObjects = selectedSports.map((sportName) => ({
      name: sportName,
      playerListUploaded: false,
    }));

    const userEmail = sessionStorage.getItem('userEmail');
    const userFullName = sessionStorage.getItem('userFullName');
    const userPhoneNumber = sessionStorage.getItem('userPhoneNumber');

    try {
      const response = await fetch(`${BACKEND_URL}/update-sports`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          collegeName: userCollegeName,
          selectedSports: sportsObjects,
          userEmail,
          userFullName,
          userPhoneNumber,
        }),
        credentials: 'include',
      });

      const result = await response.json();
      if (response.ok) {
        setIsLoading(false);
        MySwal.fire({
          title: 'Success',
          text: 'Sports selection updated successfully',
          icon: 'success',
          confirmButtonText: 'OK',
          color:"#fff",
          background:"#333",
          theme:"dark",
          backdrop: true,
        });
        navigate(`/collegedashboard`);
      } else {
        console.error('Failed to update sports selection:', result);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className={styles.sportsSelectionPage}>
      {loading && <LoadingSpinner />} 
      <nav className={styles.nav}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <span className={styles.pageTitle}>Sport Selection Page</span>
        </div>
      </nav>
      <CustomNavbar />
      <div className={styles.centerContainer}>
        <div className={styles.selectionContainer}>
          <h2 className={styles.headerText}>Choose Your Sport(s)</h2>
          <div className={styles.inputContainer}>
            <label className={styles.label}>
              College Name:
              <input
                type="text"
                value={userCollegeName}
                onChange={(e) => setUserCollegeName(e.target.value)}
                className={styles.inputField}
                placeholder="Enter college name"
                required
              />
            </label>
          </div>
          <div className={styles.sportsList}>
            {sports.map((sport) => (
              <button
                key={sport.name}
                className={`${styles.sportButton} ${selectedSports.includes(sport.name) ? styles.activeSport : ''}`}
                onClick={() => toggleSportSelection(sport)}
              >
                {sport.name}
              </button>
            ))}
          </div>
          <button
            className={styles.submitButton}
            onClick={handleSubmit}
            disabled={!userCollegeName}
          >
            {isModifyMode ? 'Update Sports' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SportsSelectionPage;
